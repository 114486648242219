

<template>
  <v-app id="inspire" >
    <v-navigation-drawer
      v-model="drawer"
      app
    >
      <!--  -->
    </v-navigation-drawer>

    <v-app-bar app dark color="#1d1d1d">
      <!--<v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>-->

      <v-toolbar-title>EH Smith | Forecasting Tool</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn @click="reload()" outlined color="error" tile class="mr-3">Reset to defaults</v-btn>
    </v-app-bar>

    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
  export default {
    data: () => ({ drawer: false }),
    methods: {
      reload() {
        location.reload(true)
      }
    }
  }
</script>