<template>
<v-container fluid>
    <v-dialog fullscreen v-model="isAuth">
        <v-card>
            <v-card-title>Login</v-card-title>
            <v-row class="pt-15 mt-15">
                <v-col cols="6" offset="3">

                    <v-text-field outlined label="Password" type="password" v-model="password"></v-text-field>
                    <v-btn block color="primary" @click="login()">Login</v-btn>
                </v-col>
            </v-row>
        </v-card>
    </v-dialog>
    <v-row>
        <v-col cols="8">
            <v-card tile>
                <v-card-title>FORECAST

                </v-card-title>
                <v-row class="mr-3 mt-n14">
                    <v-col cols="3" offset="2">
                        <v-select label="Supplier" outlined dense class="rounded-0" :items="suppliers" @change="updateBudget()" v-model="supplier"></v-select>
                    </v-col>
                    <v-col cols="3">
                        <v-select label="Factory" outlined dense class="rounded-0" :items="factories" v-model="factory" :disabled="supplier === null"></v-select>
                    </v-col>
                    <v-col cols="3">
                        <v-select label="Product Group" outlined dense class="rounded-0" :items="filteredProductGroups" v-model="productGroup" :disabled="factory === null"></v-select>
                    </v-col>
                    <v-col cols="1">
                        <v-btn  @click="updateBudget(); updateActualsSeries()">Go</v-btn>
                    </v-col>
                </v-row>

                <div id="wrapper">
                    <div id="chart-line">
                        <apex-chart type="line" height="357" :options="chartOptions" :series="series"></apex-chart>
                    </div>
                    <v-card-title>ACTUALS</v-card-title>
                    <div id="chart-line2">
                        <apex-chart type="area" height="200" :options="chartOptionsLine2" :series="seriesLine2"></apex-chart>
                    </div>
                </div>
            </v-card>
        </v-col>
        <v-col cols="4">
            <v-card height="100%" tile>
                <v-card-title>NEW SCENARIO
                    <v-spacer></v-spacer>
                    <v-btn small color="black" dark tile @click="dialog = !dialog">
                        <v-icon left>mdi-open-in-new</v-icon>Scheduler
                    </v-btn>
                </v-card-title>
                <v-card-subtitle class="text-caption">See how a new order would effect the budget</v-card-subtitle>
                <v-row class="mr-2 ml-2">
                    <v-col cols="6" class="mb-n7">
                        <v-text-field label="Scenario Name" outlined dense class="rounded-0" v-model="scenario.name"></v-text-field>

                    </v-col>
                    <v-col cols="6" class="mb-n7">
                        <v-select label="Supplier" outlined dense class="rounded-0" v-model="scenario.supplier" :items="suppliers"></v-select>
                    </v-col>
                    <v-col cols="12" class="mb-n7">
                        <v-textarea label="Scenario Description" outlined dense class="rounded-0" rows="3" v-model="scenario.description"></v-textarea>
                    </v-col>
                    <v-col cols="6" class="mb-n6">
                        <v-select label="Factory" outlined dense class="rounded-0" v-model="scenario.factory" :items="factories"></v-select>
                    </v-col>
                    <v-col cols="6" class="mb-n6">
                        <v-select label="Product Group" outlined dense class="rounded-0" v-model="scenario.productGroup" :items="productGroups"></v-select>
                    </v-col>

                    <v-col cols="6" class="mb-n7" v-for="(item, index) in chartOptions.xaxis.categories" :key="index">
                        <v-text-field outlined dense :label="formatDateLabel(item)" type="number" class="rounded-0" v-model="scenario.amounts[formatDateLabel(item)]"></v-text-field>
                    </v-col>
                </v-row>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="black" dark tile class="mt-5 mr-3" @click="addScenario" :disabled="saveScenarioDisabled">Save</v-btn>
                </v-card-actions>
            </v-card>
        </v-col>
        <v-col cols="12">
            <v-card tile>
                <v-card-title>Data
                    <v-spacer></v-spacer>
                    <v-btn small color="black" dark tile @click="fileUploadDialog = !fileUploadDialog">
                        <v-icon left>mdi-open-in-new</v-icon>Upload New Budget
                    </v-btn>
                    <v-btn class="ml-3" small color="black" dark tile @click="tableExport.dialog = true">
                        <v-icon left>mdi-file-export-outline</v-icon>Export Table
                    </v-btn>
                </v-card-title>
                <v-data-table id="dataTableExport" :key="`dataTable${keys.dataTable}`" :headers="dataHeaders" :items="defaultItems" hide-default-footer>
                    <template v-slot:[`item.actions`]="{ item }">
                        <center>
                            <v-switch :disabled="true" color="#1d1d1d" v-model="item.isShown" @change="updateStatus(item.id, $event)"></v-switch>
                        </center>
                    </template>
                    <template v-slot:[`item.label`]="{ item }">
                        <v-tooltip bottom color="secondary">
                            <template v-slot:activator="{ on, attrs }">
                                <div v-bind="attrs" v-on="on">
                                    {{ item.label }}
                                </div>
                            </template>
                            {{item.tooltip??'demo tooltip'}}
                        </v-tooltip>
                    </template>
                </v-data-table>
                <v-card-title>Scenarios
                    <v-spacer />
                </v-card-title>
                <v-data-table id="scenariosTableExport" :key="`scenarioTable${keys.scenarioTable}`" :headers="headers" :items="filteredItems">

                    <template v-for="(header, index) in headers" v-slot:[`item.${header.value}`]="{ item }">
                        <!-- <template v-slot:[`item.actions`]="{ item }"> -->

                        <center :key="`actions${header.value}${index}`" v-if="header.value === 'actions'">
                            <v-switch color="#1d1d1d" v-model="item.isShown" @change="updateStatus(item.id, $event)"></v-switch>
                        </center>
                        <!-- </template> -->
                        <!-- <template v-slot:[`item.edit`]="{ item }"> -->
                        <v-card :key="`edit${header.value}${index}`" v-if="header.value === 'edit'" flat color="transparent" :width="item.isEditing?'80px':'50px'">
                            <center>
                                <v-icon @click="toggleItemEdits(item)">{{(item.isEditing)?'mdi-cancel':'mdi-pencil-outline'}}</v-icon>
                                <v-icon v-if="item.isEditing" @click="updateScenario(item)" class="ml-3">mdi-content-save</v-icon>
                            </center>
                        </v-card>
                        <!-- </template>
                        <template v-slot:[`item.delete`]="{  }"> -->
                        <center :key="`delete${header.value}${index}`" v-if="header.value === 'delete'">
                            <v-icon @click="deleteScenario(item.id)">mdi-delete-outline</v-icon>
                        </center>
                        <!-- </template> -->
                        <v-text-field v-if="item.isEditing && header.value === 'label'" :key="`tl${header.value}${index}`" dense :type="header.type" hide-details v-model="item[header.value]">
                        </v-text-field>
                        <v-select v-else-if="item.isEditing && ['product_group'].includes(header.value)" :key="`tg${header.value}${index}`" dense :items="productGroups" :type="header.type" hide-details v-model="item[header.value]">
                        </v-select>
                        <v-select v-else-if="item.isEditing && ['factory'].includes(header.value)" :key="`tf${header.value}${index}`" dense :items="factories" :type="header.type" hide-details v-model="item[header.value]">
                        </v-select>
                        <v-text-field v-else-if="item.isEditing && ['factory','product_group','label', 'actions', 'edit', 'delete'].includes(header.value) === false" :key="`tf${header.value}${index}`" dense :type="header.type" hide-details v-model="item[header.value]">
                        </v-text-field>
                        <div v-else :key="`tc${header.value}${index}`">
                            {{ item[header.value] }}
                        </div>
                    </template>
                </v-data-table>
            </v-card>
        </v-col>
    </v-row>
    <v-dialog v-model="dialog" width="50%">
        <v-card>
            <v-card-title>Scheduler
                <v-spacer></v-spacer>
                <v-icon @click="dialog = !dialog">mdi-close</v-icon>
            </v-card-title>
            <v-card-subtitle>Add a fixed schedule to your scenario</v-card-subtitle>
            <v-select multiple small-chips clearable deletable-chips label="Months To Apply To" outlined dense class="mr-5 ml-5 rounded-0" :items="dataHeaders.filter(item =>  item.value.charAt(item.value.length-1) == '1')" v-model="selectedMonths" item-text="text" item-value="text"></v-select>
            <v-text-field label="Quantity" outlined dense class="mr-5 ml-5 rounded-0" type="number" v-model="quantity"></v-text-field>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="black" tile dark @click="applyQuantities()">Apply To Scenario</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>

    <v-dialog v-model="fileUploadDialog" width="50%">
        <v-card>
            <v-card-title>Budget Uploader
                <v-spacer></v-spacer>
                <v-icon @click="fileUploadDialog = !fileUploadDialog">mdi-close</v-icon>
            </v-card-title>
            <v-card-subtitle>Replace Budget with up to date data</v-card-subtitle>

            <v-file-input label="Budget File" outlined dense class="mr-5 ml-5 rounded-0" v-model="file"></v-file-input>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-progress-circular v-show="loadingBudget" indeterminate></v-progress-circular>
                <v-btn color="black" tile dark @click="parseFile()" v-show="!loadingBudget">Upload</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
    <v-dialog v-model="tableExport.dialog" width="50%">
        <v-card>
            <v-card-title>Export Tables
                <v-spacer></v-spacer>
                <v-icon @click="tableExport.dialog = !tableExport.dialog">mdi-close</v-icon>
            </v-card-title>
            <v-card-subtitle>Export your tables</v-card-subtitle>

            <v-select outlined dense class="mr-5 ml-5 rounded-0" label="Export file type" :items="tableExport.fileOptions" v-model="tableExport.fileType"></v-select>
            <v-select outlined dense class="mr-5 ml-5 rounded-0" label="Table" :items="tableExport.tableOptions" v-model="tableExport.tableChoice" item-text="text" item-value="value"></v-select>
            <v-text-field outlined dense class="mr-5 ml-5 rounded-0" label="Export file Name" v-model="tableExport.fileName"></v-text-field>

            <v-card-actions>
                <v-switch color="#1d1d1d" outlined dense class="mr-5 ml-1 mt-0 rounded-0" label="Include date" v-model="tableExport.includeDate"></v-switch>
                <v-spacer></v-spacer>
                <v-btn :disabled="tableExportButtonDisabled" color="black" tile dark class="mr-1 ml-5 mt-0 rounded-0" @click="exportTable()">Export</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>

</v-container>
</template>

<script src="https://cdn.sheetjs.com/xlsx-latest/package/dist/xlsx.full.min.js"></script>
<script>
import * as XLSX from 'xlsx';
import apiClient from '@/services';
import moment from 'moment';

export default {

    data() {
        return {
            isAuth: true,
            password: '',
            keys: {
                dataTable: 0,
                scenarioTable: 0
            },
            tableExport: {
                dialog: false,
                fileOptions: ['CSV', 'XLS', 'XLSX'],
                tableOptions: [{
                        text: 'Data',
                        value: 'dataTableExport'
                    },
                    {
                        text: 'Scenarios',
                        value: 'scenariosTableExport'
                    },
                    {
                        text: 'All',
                        value: 'all'
                    }
                ],
                fileName: '',
                fileType: '',
                tableChoice: '',
                includeDate: false
            },
            dialog: false,
            fileUploadDialog: false,
            loadingBudget: false,
            file: null,
            selectedMonths: [],
            quantity: null,
            factories: [],
            suppliers: [],
            productGroups: [],
            allFactories: [],
            allSuppliers: [],
            allProductGroups: [],
            allFields: [],
            factory: null,
            supplier: null,
            productGroup: null,
            scenario: {
                name: null,
                description: null,
                supplier: null,
                factory: null,
                productGroup: null,
                amounts: {}
            },
            series: [
                /*{
                    name: 'Forecast',
                    data: []
                },
                {
                    name: 'Budget',
                    data: []
                }*/
            ],
            chartOptions: {
                chart: {
                    id: 'fb',
                    group: 'social',
                    type: 'line',
                    background: '0',
                    toolbar: {
                        show: false,
                    }
                },
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    curve: 'smooth',
                    color: '#1d1d1d',
                    width: 3,
                },
                markers: {
                    size: 5,
                    color: '#1d1d1d'
                },
                colors: ['#00BCD4', '#1d1d1d'],
                xaxis: {
                    //categories: ['Jan 22', 'Feb 22', 'Mar 22', 'Apr 22', 'May 22', 'Jun 22', 'Jul 22', 'Aug 22', 'Sep 22', 'Oct 22', 'Nov 22', 'Dec 22'],
                     type:'datetime',
                     labels:{
                         format:'MMM YY'
                     }
                    // labels: {
                    //     formatter: function(value, timestamp, opts) {
                    //         return opts.dateFormatter(new Date(timestamp)).format("dd MMM")
                    //     }
                    // }
                },
                yaxis: {
                    labels: {
                        style: {
                            fontSize: '10px'
                        },
                        tickAmount: 10,

                        formatter: function (value) {
                            if (value > 1000000) {
                                return (value / 1000000).toFixed(2) + 'm'
                            } else {
                                return (value / 1000).toFixed(2) + 'k'
                            }

                        }
                    }
                },

            },
            seriesLine2: [],
            chartOptionsLine2: {
                chart: {
                    id: 'tw',
                    group: 'social',
                    type: 'line',
                    background: '0',
                    toolbar: {
                        show: false,
                    }
                },
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    curve: 'smooth',
                    color: '#000000',
                    width: 3,
                },
                markers: {
                    size: 5,
                    color: '#1d1d1d'
                },
                colors: ['#1d1d1d'],
                xaxis: {
                    //categories: ['Jan 22', 'Feb 22', 'Mar 22', 'Apr 22', 'May 22', 'Jun 22', 'Jul 22', 'Aug 22', 'Sep 22', 'Oct 22', 'Nov 22', 'Dec 22'],
                     type:'datetime',
                     labels:{
                         format:'MMM yy'
                     }
                },
                yaxis: {
                    labels: {
                        style: {
                            fontSize: '10px'
                        },
                        tickAmount: 10,

                        formatter: function (value) {

                            if (value > 1000000) {
                                return (value / 1000000).toFixed(2) + 'm'
                            } else {
                                return (value / 1000).toFixed(2) + 'k'
                            }

                        }
                    }
                },
            },
            headers: [{
                    text: 'Active',
                    align: 'start',
                    sortable: false,
                    value: 'actions',
                },
                {
                    text: 'Edit',
                    align: 'start',
                    sortable: false,
                    value: 'edit',
                },
                {
                    text: 'Delete',
                    align: 'start',
                    sortable: false,
                    value: 'delete',
                },
                {
                    text: 'Label',
                    align: 'start',
                    sortable: true,
                    value: 'label',
                    width: '100'
                },
                {
                    text: 'Factory',
                    align: 'start',
                    sortable: true,
                    value: 'factory',
                    width: '100'
                },
                {
                    text: 'Product Group',
                    align: 'start',
                    sortable: true,
                    value: 'product_group',
                    width: '100'
                },

            ],
            items: []
        }
    },
    created() {
        if(localStorage.getItem('token') != undefined){
            this.isAuth = false;
            this.createDates()
        }
        
    },
    async mounted() {
        await this.createBudgets()
        await this.createActuals()
        await this.createScenarios()

        await this.updateScenarioSeries()

    },

    computed: {
        dataHeaders() {
            return this.headers.filter(header => !(['edit', 'delete']).includes(header.value))
        },
        textEditHeaders() {
            let headers = this.headers.filter(header => !(['actions', 'edit', 'delete']).includes(header.value))
            for (let i = 0; i < headers.length; i++) {
                headers[i]['type'] = (['label', 'factory', 'product_group']).includes(headers[i].value) ? 'text' : 'number'
            }
            return headers
        },
        tableExportButtonDisabled() {
            return ([this.tableExport.fileName, this.tableExport.fileType, this.tableExport.tableChoice].indexOf("") > -1)
        },
        saveScenarioDisabled() {
            return ([this.scenario.productGroup, this.scenario.factory, this.scenario.supplier, this.scenario.name].includes(null))
        },
        filteredProductGroups() {
            if (this.factory === null) {
                return this.productGroups
            } else {
                let array = []
                for (var i = 0; i < this.allFields.length; i++) {
                    if (this.allFields[i].factory === this.factory) {
                        array.push(this.allFields[i].productGroup)
                    }
                }
                return array
            }
        },
        filteredItems() {
            if (this.factory === null) {
                let arr = [];
                for (var i = 0; i < this.items.length; i++) {
                    if (this.items[i].label != 'Budget' && this.items[i].label != 'Actuals') {
                        arr.push(this.items[i])
                    }
                }
                return arr
            } else {
                let array = []
                for (var a = 0; a < this.items.length; a++) {
                    if (this.items[a].factory === this.factory && this.items[a].label != 'Budget') {
                        array.push(this.items[a])
                    }
                }
                return array
            }
        },
        defaultItems() {
            let array = []
            for (var i = 0; i < this.items.length; i++) {
                if (this.items[i].label === 'Budget' || this.items[i].label === 'Actuals') {
                    array.push(this.items[i])
                }
            }
            return array
        }
    },
    methods: {
        formatDateLabel(item) {
            return moment(item).format('MMM YY')
        },
        toggleItemEdits(item) {
            for (let i = 0; i < this.items.length; i++) {
                if (item.id === this.items[i].id) {
                    this.items[i].isEditing = !this.items[i].isEditing
                } else {
                    this.items[i].isEditing = false
                }
            }
        },
        deleteScenario(itemId) {

            apiClient
                .deleteScenario(itemId)
                .then(() => {
                    this.createScenarios();
                })
                .catch(err => console.error(err))
        },
        updateScenario(item) {
            item.isEditing = false;
            const ignoreArray = new Set(['factory', 'id', 'isEditing', 'isShown', 'label', 'product_group', 'supplier', 'category', 'description'])
            const dates = Object.keys(item).filter(v => !ignoreArray.has(v));
            console.log(item)
            const values = []
            for (let i = 0; i < dates.length; i++) {
                values.push({
                    date: dates[i],
                    value: parseInt(item[dates[i]])
                })

            }

            const packet = {
                scenarioId: item.id,
                supplier: item.supplier ?? '',
                factory: item.factory,
                product: item.product_group,
                category: item.category ?? '',
                name: item.label,
                description: item.description ?? '',
                isShown: item.isShown,
                values: values
            }
            apiClient
                .updateScenario(packet)
                .then(() => {
                    this.createScenarios();
                })
                .catch(err => console.error(err))
        },
        exportTable() {
            let wb = XLSX.utils.book_new();

            if (this.tableExport.tableChoice === 'all') {
                let ws = XLSX.utils.table_to_sheet(document.getElementById(this.tableExport.tableOptions[0].value));
                XLSX.utils.book_append_sheet(wb, ws, this.tableExport.tableOptions[0].text);
                let ws2 = XLSX.utils.table_to_sheet(document.getElementById(this.tableExport.tableOptions[1].value));
                XLSX.utils.book_append_sheet(wb, ws2, this.tableExport.tableOptions[1].text);
            } else {
                ws = XLSX.utils.table_to_sheet(document.getElementById(this.tableExport.tableChoice));
                XLSX.utils.book_append_sheet(wb, ws, this.tableExport.fileName);
            }

            const fileName = `${this.tableExport.fileName}${(this.tableExport.includeDate)?' - ' + new Date().toLocaleDateString("en-GB"):''}.${this.tableExport.fileType}`

            XLSX.writeFile(wb, fileName);
        },
        async createActuals() {
            apiClient.getActuals()
                .then(res => {

                    let actuals = {
                        label: 'Actuals',
                        factory: '-',
                        product_group: '-',
                        isShown: true
                    }

                    for (var i = 0; i < res.data.length; i++) {

                        if (actuals[res.data[i].date] === undefined) {
                            actuals[res.data[i].date] = Math.round(parseFloat(res.data[i].quantity))
                        } else {
                            actuals[res.data[i].date] = Math.round(actuals[res.data[i].date] + parseFloat(res.data[i].quantity))
                        }
                    }

                    let actualsSeries = []
                    let keys = Object.keys(actuals)

                    keys.sort()
                    const ignoreKeys = ['label', 'factory', 'product_group', 'isShown', 'id']
                    for (var a = 0; a < keys.length; a++) {
                        if (!ignoreKeys.includes(keys[a])) {
                            let xAxis = keys[a]
                            let yAxis = actuals[keys[a]]

                            actualsSeries.push({
                                x: xAxis,
                                y: yAxis
                            })
                        }
                    }

                    actualsSeries.sort(this.compare)

                    this.seriesLine2.push({
                        name: 'Actuals',
                        data: actualsSeries
                    })

                    this.items.push(actuals)
                })
        },
        async createBudgets() {
            apiClient.getBudget()
                .then(res => {

                    let budgetRow = {
                        label: 'Budget',
                        factory: '-',
                        product_group: '-',
                        isShown: true
                    }
                    let factories = [];
                    let suppliers = [];
                    let productGroups = [];
                    let allFields = []
                    let budgetSeries = [];
                    for (var i = 0; i < res.data.length; i++) {

                        if (budgetRow[res.data[i].ds] === undefined) {
                            budgetRow[res.data[i].ds] = Math.round(parseFloat(res.data[i].amount))
                        } else {
                            budgetRow[res.data[i].ds] = Math.round(budgetRow[res.data[i].ds] + parseFloat(res.data[i].amount))
                        }
                        allFields.push({
                            factory: res.data[i].factory,
                            supplier: res.data[i].supplier,
                            productGroup: res.data[i].product
                        })
                        factories.push(res.data[i].factory);
                        suppliers.push(res.data[i].supplier)
                        productGroups.push(res.data[i].product);
                    }

                    let keys = Object.keys(budgetRow)

                    let lookAheadBudgetRow = {
                        label: 'Budget',
                        factory: '-',
                        product_group: '-',
                        isShown: true
                    }

                    const ignoreKeys = ['label', 'factory', 'product_group', 'isShown']
                    for (var a = 0; a < keys.length; a++) {
                        if (!ignoreKeys.includes(keys[a])) {
                            if (moment(keys[a]).isSameOrAfter(moment().startOf('month'))) {
                                let xAxis = keys[a]
                                let yAxis = budgetRow[keys[a]]

                                lookAheadBudgetRow[xAxis] = yAxis

                                budgetSeries.push({
                                    x: xAxis,
                                    y: yAxis
                                })
                            } else {
                                let xAxis = moment(keys[a]).add(1, 'year').format("YYYY-MM-DD")
                                let yAxis = budgetRow[keys[a]]

                                lookAheadBudgetRow[xAxis] = yAxis

                                budgetSeries.push({
                                    x: xAxis,
                                    y: yAxis
                                })
                            }
                        }
                    }

                    budgetSeries.sort(this.compare)

                    this.items.push(lookAheadBudgetRow)

                    this.series.push({
                        name: 'Budget',
                        data: budgetSeries
                    })

                    this.factories = [...new Set(factories)];
                    this.suppliers = [...new Set(suppliers)];
                    this.productGroups = [...new Set(productGroups)];
                    this.allFactories = [...new Set(factories)];
                    this.allSuppliers = [...new Set(suppliers)];
                    this.allProductGroups = [...new Set(productGroups)];
                    this.allFields = [...new Set(allFields)];

                })
        },
        async createScenarios() {

            this.items = []

            apiClient.getScenarios()
                .then(res => {

                    for (var i = 0; i < res.data.length; i++) {
                        let row = {
                            label: res.data[i].name,
                            factory: res.data[i].factory,
                            product_group: res.data[i].product,
                            isShown: res.data[i].is_shown,
                            id: res.data[i].scenario_id,
                            isEditing: false,
                        }
                        let valueKeys = Object.keys(res.data[i].values);
                        for (var a = 0; a < res.data[i].values.length; a++) {
                            const key = Object.keys(res.data[i].values[a])
                            row[key] = Object.values(res.data[i].values[a])[0]
                        }

                        this.items.push(row)
                    }
                })
                .finally(() => {
                    this.updateScenarioSeries()

                })
        },

        createDates() {

            const min = moment()
            const max = moment().add(11, 'month')

            this.chartOptions.xaxis['min'] = min
            this.chartOptions.xaxis['max'] = max
            this.chartOptionsLine2.xaxis['min'] = min
            this.chartOptionsLine2.xaxis['max'] = max

            let axis = []
            for (var i = 0; i < 12; i++) {
                let newDate = moment().startOf('month').add(i, 'month')

                let obj = {
                    text: newDate.format('MMM YY'),
                    align: 'start',
                    sortable: true,
                    value: newDate.format('YYYY-MM-DD')
                };

                this.headers.push(obj)

                axis.push(newDate.toLocaleString('en-gb', {
                    month: 'short',
                    year: 'numeric'
                }))

            }

            this.chartOptions = {
                ...this.chartOptions,
                xaxis: {
                    categories: axis,
                    type: 'datetime',
                    labels: {
                        formatter: function (value, timestamp) {
                            return moment(value).format('MMM YY')
                        },
                    }
                }
            }
            this.chartOptionsLine2 = {
                ...this.chartOptionsLine2,
                xaxis: {
                    categories: axis,
                    type: 'datetime',
                    labels: {
                        formatter: function (value, timestamp) {
                            return moment(value).format('MMM YY')
                        },
                    }
                }
            }

        },
        applyQuantities() {

            for (var i = 0; i < this.selectedMonths.length; i++) {
                this.scenario.amounts[this.selectedMonths[i]] = parseInt(this.quantity);
            }
            this.selectedMonths = [];
            this.quantity = null;
            this.dialog = false;

        },
        async parseFile() {

            this.loadingBudget = true;
            const data = await this.file.arrayBuffer(); // eslint-disable-next-line
            /* data is an ArrayBuffer */
            const workbook = XLSX.read(data, {
                cellDates: true
            }); //
            let sheet = "WB SCHEDULE";

            var fileData = XLSX.utils.sheet_to_json(workbook.Sheets[sheet], {
                blankrows: false,
                cellDates: true,
                defval: null
            });

            apiClient.uploadBudget(fileData)
                .then(() => {
                    this.loadingBudget = false;
                    this.fileUploadDialog = false;
                    this.file = null;
                    location.reload(true);
                })

        },
        async updateBudget() {
            console.log('--- CALLED UPDATE BUDGET --- ')
            this.items = this.items.filter(x => x.label != 'Budget')

            apiClient.getBudget()
                .then(res => {

                    let budgetRow = {
                        label: 'Budget',
                        factory: '-',
                        product_group: '-',
                    }

                    let budgetSeries = [];
                    for (var i = 0; i < res.data.length; i++) {

                        if (budgetRow[res.data[i].ds] === undefined && res.data[i].factory === this.factory && res.data[i].product === this.productGroup) {
                            budgetRow[res.data[i].ds] = Math.round(parseFloat(res.data[i].amount))
                            // } else if (budgetRow[res.data[i].ds] != undefined && (this.factory === null || res.data[i].factory === this.factory) && (this.productGroup === null || res.data[i].product === this.productGroup)) {
                        }/*
                        else {
                                                   let toRound = parseFloat(res.data[i].amount)
                                                   budgetRow[res.data[i].ds] = Math.round(toRound)
                                               }*/

                    }

                    let keys = Object.keys(budgetRow)

                    let lookAheadBudgetRow = {
                        label: 'Budget',
                        factory: '-',
                        product_group: '-',
                        isShown: true
                    }

                    const ignoreKeys = ['label', 'factory', 'product_group', 'isShown']
                    for (var a = 0; a < keys.length; a++) {
                        if (!ignoreKeys.includes(keys[a])) {
                            if (moment(keys[a]).isSameOrAfter(moment().startOf('month'))) {
                                let xAxis = keys[a]
                                let yAxis = budgetRow[keys[a]]

                                lookAheadBudgetRow[xAxis] = yAxis

                                budgetSeries.push({
                                    x: xAxis,
                                    y: yAxis
                                })
                            } else {
                                let xAxis = moment(keys[a]).add(1, 'year').format("YYYY-MM-DD")
                                let yAxis = budgetRow[keys[a]]

                                lookAheadBudgetRow[xAxis] = yAxis

                                budgetSeries.push({
                                    x: xAxis,
                                    y: yAxis
                                })
                            }
                        }
                    }

                    /*

                                        let keys = Object.keys(budgetRow)
                                        const ignoreKeys = ['label', 'factory', 'product_group', 'isEditing'];

                                        for (var a = 0; a < keys.length; a++) {
                                            if (!ignoreKeys.includes(keys[a]) && moment(keys[a]).isSameOrAfter(moment().startOf('month'))) {
                                                let xAxis = keys[a]
                                                let yAxis = budgetRow[keys[a]]

                                                budgetSeries.push({
                                                    x: xAxis,
                                                    y: yAxis
                                                })
                                                // budgetSeries.push(budgetRow[keys[a]])
                                            }
                                        }
                    */
                    budgetSeries.sort(this.compare)

                    this.items.push(lookAheadBudgetRow)
                    this.series = this.series.filter(x => x.name != 'Budget')
                    this.series.push({
                        name: 'Budget',
                        data: budgetSeries
                    })
                    
                })
        },
        addScenario() {
            console.log(this.scenario)
            let packet = JSON.parse(JSON.stringify((this.scenario)))
            console.log(packet)
            let amounts = {}

            for (const key in packet.amounts) {
                if (Object.hasOwnProperty.call(packet.amounts, key)) {
                    let date = moment(`01 ${key}`).format('YYYY-MM-DD')
                    amounts[date] = packet.amounts[key]
                }
            }

            packet.amounts = amounts

            apiClient
                .addScenario(packet)
                .then(() => {
                    this.scenario = {
                        name: '',
                        description: '',
                        supplier: null,
                        factory: null,
                        productGroup: null,
                        amounts: {}
                    }
                    // this.updateScenarios()
                    this.refreshKeys()
                })
                .catch(err => console.error(err))
        },
        refreshKeys() {
            for (const key in this.keys) {
                if (Object.hasOwnProperty.call(this.keys, key)) {
                    this.keys[key]++;
                }
            }
        },
        updateStatus(id, status) {

            apiClient.setStatus(id, status)
                .then(async () => {

                    await this.updateScenarioSeries()
                    await this.updateActualsSeries()
                })

        },
        async updateActualsSeries() {
         

            if (this.factory !== null && this.productGroup !== null) {
                this.seriesLine2 = this.seriesLine2.filter(x => x.name != 'Actuals')
                this.items = this.items.filter(x => x.label != 'Actuals');
                apiClient.getActuals()
                    .then(res => {
                        let actuals = {
                            label: 'Actuals',
                            factory: '-',
                            product_group: '-',
                            isShown: true
                        }

                        for (var i = 0; i < res.data.length; i++) {
                            if (actuals[res.data[i].date] === undefined && res.data[i].factory === this.factory && res.data[i].category === this.productGroup) {
                                actuals[res.data[i].date] = Math.round(parseFloat(res.data[i].quantity))
                            } else if (actuals[res.data[i].date] != undefined && res.data[i].factory === this.factory && res.data[i].product_group === this.productGroup) {
                                actuals[res.data[i].date] = Math.round(actuals[res.data[i].date] + parseFloat(res.data[i].quantity))
                            }
                        }

                        let actualsSeries = []
                        let keys = Object.keys(actuals)

                        keys.sort()
                        const ignoreKeys = ['label', 'factory', 'product_group', 'isShown', 'isEditing']
                        for (var a = 0; a < keys.length; a++) {
                            if (!ignoreKeys.includes(keys[a])) {
                                let xAxis = keys[a]
                                let yAxis = actuals[keys[a]]

                                actualsSeries.push({
                                    x: xAxis,
                                    y: yAxis
                                })
                            }
                        }

                        // dynamically generates extra forecast months if less than 12 in the array

                        let minimumDate = actualsSeries[0].x;
                        let maximumDate = actualsSeries[0].x;

                        for (let index = 0; index < actualsSeries.length; index++) {
                            if (moment(actualsSeries[index].x).isBefore(moment(minimumDate))) {
                                minimumDate = actualsSeries[index].x
                            }
                            if (moment(actualsSeries[index].x).isAfter(moment(maximumDate))) {
                                maximumDate = actualsSeries[index].x
                            }
                        }

                        const dateDiff = moment(maximumDate).diff(minimumDate, 'months', true)

                        if (dateDiff < 11) {
                            for (let index = 1; index < (12 - dateDiff); index++) {
                                actualsSeries.push({
                                    x: moment(maximumDate).add(index, 'month').format('YYYY-MM-DD'),
                                    y: ''
                                })
                            }
                        }

                        this.seriesLine2.push({
                            name: 'Actuals',
                            data: actualsSeries
                        })

                        const formattedActuals = {}

                        for (const key in actuals) {
                            if (!ignoreKeys.includes(key)) {
                                if (Object.hasOwnProperty.call(actuals, key)) {

                                    let newKey = key

                                    formattedActuals[newKey] = actuals[key]
                                }
                            } else {
                                formattedActuals[key] = actuals[key]
                            }
                        }

                        this.items.push(formattedActuals)
                        this.updateScenarioSeries()
                    })
            }
        },
        updateScenarioSeries() {

             console.log('--- CALLED UPDATE SCEN --- ')

            let forecastData = {}
            for (var b = 0; b < this.items.length; b++) {
                if (this.items[b].label !== 'Budget' && this.items[b].isShown) {
                    let keys = Object.keys(this.items[b]);
                    const ignoreKeys = ['label', 'factory', 'product_group', 'isShown']
                    for (var c = 0; c < keys.length; c++) {
                        if (!ignoreKeys.includes(keys[c])) {

                            if (forecastData[keys[c]] === undefined) {
                                forecastData[keys[c]] = this.items[b][keys[c]]
                            } else {
                                forecastData[keys[c]] = forecastData[keys[c]] + this.items[b][keys[c]]
                            }
                        }
                    }
                }
            }

            let forecastSeries = [];
            let keys = Object.keys(forecastData);

            for (var d = 0; d < keys.length; d++) {
                if (!(['id', 'isEditing']).includes(keys[d])) {
                    let xAxis = keys[d];
                    let yAxis = forecastData[keys[d]]
                    forecastSeries.push({
                        x: xAxis,
                        y: yAxis
                    })
                }

            }

            forecastSeries.sort(this.compare)

            console.log(forecastSeries)

            this.series = structuredClone(this.series.filter(x => x.name != 'Forecast + Actuals'))
            if (forecastSeries.length > 0) {
                this.series.push({
                    name: 'Forecast + Actuals',
                    data: forecastSeries
                })
            }

        },
        compare(a, b) {

            if (a.x < b.x) {
                return -1;
            }
            if (a.x > b.x) {
                return 1;
            }
            return 0;
        },
        login(){
            apiClient.login(this.password)
                .then(res => {
                    console.log(res)
                    localStorage.setItem('token', res.data.token)
                    location.reload(true)
                })
        }

    }
};
</script>

<style>
.my-input.v-input .v-input__slot,
.v-application .v-tooltip__content {
    border-radius: 0px;
}

.v-input input,
.v-input textarea,
.v-select {
    font-size: 0.89rem;
}

tr:has(.mdi-cancel) {
    background-color: rgba(0, 0, 0, 0.06);
}
</style>
