import axios from 'axios'
//import store from '../store'
//import router from '../router'

const apiClient = axios.create({
  baseURL: 'https://what-if-api-r97us.ondigitalocean.app',
  //baseURL: 'https://ehsmith-api-ygmmn.ondigitalocean.app',
  withCredential: false,
  headers: {
    'token': localStorage.getItem('token'),
    'Content-Type': "application/json",
    //'Origin': 'http://localhost:8080'
  }
})

export default {

  uploadBudget(data) {
    return apiClient.post('/budgets/insert', {
        data: data
    })
  },

  getBudget() {
    return apiClient.get('/budgets/get')
  },

  addScenario(data) {
    return apiClient.post('/scenarios/insert', data)
  },

  updateScenario(scenario) {
    return apiClient.post('/scenarios/update', scenario)
  },

  deleteScenario(scenarioId) {
    return apiClient.delete(`/scenarios/delete/${scenarioId}`)
  },

  getScenarios() {
    return apiClient.get('/scenarios/get-values')
  },

  setStatus(scenarioId, isShown) {
    return apiClient.post('/scenarios/status', {
        scenarioId: scenarioId,
        isShown: isShown
    })
  },

  getActuals(){
    return apiClient.get('/actuals/get')
  },

  login(password) {
    return apiClient.post('/auth/login', {
      password: password
    })
  }

}
